import { createRouter, createWebHashHistory } from 'vue-router';
import { cookieAsObject } from '@/utils/cookieToObject';

function requireCookie(to, from, next) {
  const cookieObject = cookieAsObject(document.cookie);
  const token = cookieObject.access_token;
  if (token == null) {
    next({ name: 'Login' });
  } else {
    next();
  }
}
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/control-panel',
    name: 'ControlPanel',
    component: () => import('@/views/ControlPanel.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/Feedback.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPasswordView.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPasswordView.vue'),
  },
  {
    path: '/update-expired-password/',
    name: 'UpdateExpiredPassword',
    component: () => import('@/views/UpdateExpiredPasswordView.vue'),
  },
  {
    path: '/activate-account/:token',
    name: 'ActivateAccount',
    component: () => import('@/views/ActivateAccount.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue'),
    beforeEnter: requireCookie,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
