import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { VDateInput } from 'vuetify/labs/VDateInput'
import '@/assets/scss/_main.scss'


export default new createVuetify({
  components: {
    VDateInput,
    ...components,

  },
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#657bdb',
          secondary: '#53BE8C',
          accent: '#ffcc80',
          error: '#CC2020',
          success: '#53BE8C',
          'on-success': '#fff',
        },
      },
    },
  },
  defaults: {
    VSelect: { variant: 'underlined' },
    VTextField: { variant: 'underlined' },
    VDateInput: { variant: 'underlined' },
    VBtn: { variant: 'outlined', rounded: 'lg', style: 'text-transform: none;', },
    VFab: { rounded: 'xl' },
    VCardActions: {
      VBtn: { variant: 'outlined', rounded: 'lg' },
    },
    VDialog: {
      VCardActions: {
        VBtn: { variant: 'text' },
      }
    },
  },
});
